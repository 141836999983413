.navbar {
  padding: 0.25rem 1rem;
  color: #FFFFFF;
  background-color: transparent;
  text-transform: uppercase;
  .collapsing {
    transition: height 400ms ease;
  }
  #logo {
    font-family: $font-logo;
    font-size: $font-md;
    @include media-breakpoint-up(md) {
      font-size: $font-lg;
    }
  }
  .nav-item {
    margin-right: 40px;
    @include breakpoint(1366) {
      margin-right: 25px;
    }
    @include media-breakpoint-down(lg) {
      margin-right: 10px;
    }
    @include media-breakpoint-down(md) {
      margin-right: 0;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    &:first-child {
      @include media-breakpoint-down(sm) {
        margin-top: 50px;
      }
    }
    a.nav-link {
      //text-align: right;
      font-family: $font-highlight;
      font-size: $font-sm;
      color: #FFFFFF;
      opacity: 1;
      @include breakpoint(1024) {
        font-size: 12px;
        padding: 7px;
      }
      @include breakpoint(768) {
        font-size: 11px;
      }
      @include breakpoint(767) {
        font-size: $font-sm;
      }
    }
  }
  .navbar-brand h5 {
    //display: block;
    font-size: 17px;
    text-transform: none;
    //font-family: $font-regular;
  }
  &.opaque {
    background-color: #FFF;
    opacity: 0.9;
    a.nav-link, .navbar-brand {
      color: $text-color;
    }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      background-color: $text-color;
    }
    @include media-breakpoint-up(md) {
      background-color: #FFF;
      opacity: 0.9;
      a.nav-link, .navbar-brand {
        color: $text-color;
      }
    }
  }
  &.opaque-mobile-open {
    @include media-breakpoint-down(sm) {
      background: url("../assets/images/Menu.jpg") no-repeat bottom center;
      background-size: cover;
      opacity: 1;
      .navbar-nav {
        height: 100vh;
      }
      a.nav-link, .navbar-brand, .navbar-toggler {
        font-family: $font-bold;
        font-size: $font-md;
        color: #FFF;
      }
      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        background-color: #FFF;
      }
    }
    .img-logo {
      display: block;
      &.logo-blue {
        display: none;
      }
    }
  }
}
body::after{
    position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
    content:url("../assets/images/Menu.jpg");
}

.navbar-toggler {
  outline: none !important;
}
