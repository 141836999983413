// _footer.scss

footer {
  padding-top: 50px;
  padding-bottom: 120px;
  h3 {
    margin-bottom: 20px;
    color: $text-color;
  }
  h5 {
    color: $text-color;
  }
  text-transform: uppercase;
  span {
    color: $green-color;
  }
  .footer-links {
    margin-bottom: 20px;
    a {
      display: inline-block;
      h5 {
        @include media-breakpoint-down(xs) {
          font-size: $font-sm;
        }
      }
      &:not(:first-of-type) {
        h5 {
          margin-left: 20px;
        }
      }
    }
    img {
      margin: 0 10px;
      width: 36px;
      height: auto;
    
    }
  }
  .copyright {
  }
}
