// _home.scss
body#home {
  h3 {
    font-size: 30px;
    text-transform: uppercase;
  }
  .subheading {
    opacity: 0.68;
  }
  .section-header {
    margin-bottom: 6px;
    @include media-breakpoint-up(md) {
      margin-bottom: 18px;
    }
  }
  .section-description {
    margin-top: 40px;
    &.d-md-none {
      margin-top: 60px;
    }
    margin-bottom: 40px;
    @include media-breakpoint-up(md) {
      margin-bottom: 60px;
    }
  }
  section {
    h3, h5 {
      color: $gray-darker;
    }
    &.banner {
      position: relative;
      color: #FFFFFF;
      #home-cta-btn {
        h5 {
          line-height: 33px;
          font-size: 18px;
          color: #FFFFFF;
        }
        position: absolute;
        bottom: 20px;
        @include media-breakpoint-up(lg) {
          top: 43%;
          bottom: auto;
        }
        .btn {
          width: 110px;
          margin-top: 0px;
          margin-left: auto;
          margin-right: auto;
          line-height: 2;
          @include media-breakpoint-up(md) {
            width: 200px;
            line-height: 2.5;
            margin-top: 0px;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        .img-fluid {
          width: 100%;
        }
      }
    }
    &.offsets-tracker {
      padding-bottom: 30px;
      h3 {
        span {
          color: $green-color;
        }
      }
    }
    &.how-it-works {
      padding-top: 50px;
      .step-container {
        .step-text {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
      .step {
        color: $green-color;
      }
      .line-separator {
        //margin: 30px auto 30px auto;
        margin: auto;
        @include media-breakpoint-down(sm) {
          width: 75%;
          //margin: 10px auto 10px auto;
        }
      }
    }
    &.partners {
      .cloud-top {
        margin-bottom: 50px;
      }
      .cloud-bottom {
        margin-top: 50px;
      }
      background-color: rgba(#3DB4FE, 0.2);
      .featured-partners {
        @include media-breakpoint-up(lg) {
          padding-left: 50px;
          padding-right: 50px;
        }
        @include media-breakpoint-down(sm) {
          white-space: nowrap;
          overflow-x: auto;
        }
      }
      .partner-detail {
        @include media-breakpoint-down(sm) {
          display: inline-block;
          float: none;
        }
      }
      .partner-detail-row {
        min-height: 100%;
        @include media-breakpoint-up(lg) {
          @include make-row();
        }
      }
      .partner-logo {
        @include media-breakpoint-down(sm) {
          &.move-left {
            left: -250px;
          }
          margin-bottom: 20px;
        }
        @include media-breakpoint-down(xs) {
          &.move-left {
            left: -100px;
          }
          margin-bottom: 20px;
        }
        .img-wrapper {
          //position: relative;
          background-color: #FFF;
          //position: absolute;
          //width: 100%;
          @include aspect-ratio(1, 1);
          //height: 100%;
          //bottom: 0;
          padding: 10px;
          @include media-breakpoint-up(lg) {
            @include vertical-align();
            img {
              @include vertical-align();
            }
          }
        }
      }
      .partner-description {
        white-space: initial;
        min-height: 100%;
        .partner-description-content {
          background-color: #FFF;
          padding: 37px;
          min-height: 100%;
          @include media-breakpoint-up(lg) {
            margin: 10px;
          }
          h3 {
            color: $green-color;
            margin-bottom: 22px;
          }
          h5:first-of-type {
            margin-bottom: 33px;
          }
        }
        .arrow_box {
          position: relative;
          background: #FFF;
          border: 2px solid #FFF;
        }
        .arrow_box:after, .arrow_box:before {
          @include media-breakpoint-down(md) {
            bottom: 100%;
            left: 50%;
          }
          @include media-breakpoint-up(lg) {
            right: 100%;
            top: 50%;
          }
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        .arrow_box:after {
          border-color: rgba(255, 255, 255, 0);
          border-width: 15px;
          @include media-breakpoint-up(lg) {
            border-right-color: #FFF;
            margin-top: -15px;
          }
          @include media-breakpoint-down(md) {
            border-bottom-color: #FFF;
            margin-left: -15px;
          }
        }
        .arrow_box:before {
          border-color: rgba(255, 255, 255, 0);
          border-width: 15px;
          @include media-breakpoint-up(lg) {
            border-right-color: #FFF;
            margin-top: -15px;
          }
          @include media-breakpoint-down(md) {
            border-bottom-color: #FFF;
            margin-left: -15px;
          }
        }
      }
    }
  }
}

.img-logo {
  display: block;
  max-width: 220px;
  height: auto;
  @include media-breakpoint-down(md) {
    max-width: 170px;
  }
  @include media-breakpoint-down(sm) {
    max-width: 150px;
  }
  &.logo-blue {
    display: none;
  }
}

.opaque  {
  .img-logo {
    display: none;
  }
  .logo-blue {
    display: block;
  }
}

.navbar {
  padding: 15px 60px;
  opacity: 1;
  @include media-breakpoint-down(md) {
    padding: 15px;
  }
  &.opaque {
    background-color: rgba(255, 255, 255, .9);
    opacity: 1;
  }
  .navbar-brand {
    padding: 0;
  }
}
.navbar-nav {
  .nav-item {
    &:last-child() {
      margin-right: 0;
    }
  }
}

.home-banner-content {
  padding-left: 60px;
  width: 100%;
  text-align: left;
  @include media-breakpoint-down(md) {
    top: 35%;
    padding-left: 15px;
  }
  @include media-breakpoint-down(sm) {
    top: auto;
    text-align: center;
    padding: 0 15px;
    background-image: linear-gradient(180deg, rgba(42,155,231,0.00) 0%, rgba(7,122,199,0.8) 100%);
    bottom: 0 !important;
    padding-bottom: 40px;
    padding-top: 60px;
  }
  .home-banner-title {
    font-size: 48px;
    line-height: 1.4;
    color: #fff;
    margin-bottom: 20px;
    width: 100%;
    font-family: $font-bold;
    @include breakpoint(1500) {
      font-size: 40px;
      line-height: 1.2;
      margin-bottom: 10px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 10px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 23px;
      line-height: 32px;
      margin-bottom: 20px;
      text-shadow: 0 2px 27px rgba(0,100,168,0.47);
    }
    @include breakpoint(380) {
      font-size: 20px;
      line-height: 30px;
    }
  }
  .home-banner-desc {
    font-size: 32px;
    color: rgba(255, 255, 255, .9);
    letter-spacing: 0;
    line-height: 1.4;
    margin-bottom: 36px;
    width: 100%;
    max-width: 600px;
    font-family: $font-medium;
    @include breakpoint(1500) {
      font-size: 26px;
      line-height: 1.4;
      max-width: 500px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 20px;
      max-width: 320px;
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .btn-blue {
    font-family: $font-semibold;
    font-size: 36px;
    color: #1697ef;
    background-color: #fff;
    border: none;
    display: inline-block;
    outline: none;
    box-shadow: 0 3px 5px rgba(0,0,0,0.5);
    border-radius: 50px;
    padding: 15px 60px;
    text-transform: uppercase;
    text-decoration: none !important;
    transition: all .2s;
    &:hover {
      box-shadow: 0 5px 10px 1px rgba(0,0,0,0.5);
    }
    @include breakpoint(1500) {
      font-size: 26px;
      padding: 10px 45px;
    }
    @include media-breakpoint-down(lg) {
      padding: 10px 30px;
      line-height: 1.4;
      margin-top: 0px;
      font-size: 15px;
    }
  }
}