// _theme.scss

*, *:before, *:after {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
h1 {
  font-size: $font-lg;
}
h2 {
  font-size: $font-md;
  font-family: $font-bold;
}
h3, h4 {
  font-size: $font-sm;
  font-family: $font-bold;
  //color: $text-color;
}
h5, h6, p, label {
  font-size: $font-sm;
  font-family: $font-regular;
  font-weight: 400;
  //color: $text-color;
}

a {
  color: $text-color;
  &:hover {
    color: $green-color;
    text-decoration: underline;
  }
}

.form-validation-warning {
  color: red;
}

.btn {
  border-radius: 34px;
  font-size: $font-sm;
  text-transform: uppercase;
  font-family: $font-bold;
  text-decoration: none;
}
.btn-white {
  background-color: #FFFFFF;
  color: $green-color;
  &:hover {
    color: $green-color;
  }
}
.btn-green {
  background-color: $green-color;
  color: #FFF;
  &:hover {
    color: $green-color;
    background-color: #FFF;
  }
  border: 1px solid $green-color;
}
.row.spacer {
  margin-top: 40px;
}

#google-signup-btn {
  width: 250px;
  margin-top: 30px;
  margin-bottom: 30px;
}

input.form-control {
  height: 50px;
  border-radius: 100px;
  padding-left: 1rem;
}
textarea.form-control {
  border-radius: 25px;
}
// Selectize overrides
.form-control .selectize-input {
  border-radius: 100px;
  height: 50px;
  padding: 12px 15px 3px 15px;
}
.selectize-control.multi .selectize-input.has-items {
  padding: 12px 15px 3px 15px;
}
.selectize-dropdown [data-selectable] {
  padding: 5px 8px;
}
.selectize-dropdown .caption {
  display: block;
  opacity: 0.7;
  font-size: 0.9rem;
  padding-top: 10px;
}
.green-header {
  color: $green-color;
  font-family: $font-bold;
  text-transform: uppercase;
}
.card {
  font-size: $font-sm;
  .card-header {
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
  }
}

.modal-title {
  margin-left: auto;
}

.inset-content-background {
  padding: 40px 15px;
  @include media-breakpoint-up(lg) {
    padding: 40px;
  }
  margin-top: -50px;
  margin-bottom: 50px;
  background-color: #FFF;
  border: 1px solid #E2E2E2;
  box-shadow: 6px 6px 18px 0 rgba(0,0,0,0.05);
}

.pretty {
  font-size: $font-sm;
  font-family: $font-regular;
  font-weight: 400;
}

#submit-button.btn-primary:disabled {
  background-color: $grey-color;
  border-color: $grey-color;
  &:hover {
    color: $c-white
  }
}
.note a {
  color: $green-color;
  font-weight: bold;
  text-decoration: underline;
}
