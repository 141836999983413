// _about.scss
.center {
  width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @include breakpoint(1199) {
    width: 960px;
  }
  @include breakpoint(991) {
    width: 720px;
  }
  @include breakpoint(767) {
    width: 540px;
  }
  @include breakpoint(575) {
    width: 100%;
  }
}

.heading-section {
  font-family: $font-bold;
  font-size: 38px;
  line-height: 47px;
  @include breakpoint(1024) {
    line-height: 1.5;
    font-size: 30px;
  }
  @include breakpoint(767) {
    font-size: 24px
  }
  @include breakpoint(575) {
    width: 100%;
  }
}

.section-content-lg {
  color: rgba(0, 0, 0, 0.85);
  font-family: $font-medium;
  font-size: 33px;
  line-height: 44px;
  @include breakpoint(1024) {
    line-height: 1.5;
    font-size: 24px;
  }
  @include breakpoint(767) {
    font-size: 20px;
  }
  @include breakpoint(575) {
    font-size: 18px;
  }
}

.section-content-md {
  color: rgba(0, 0, 0, 0.85);
  font-family: $font-roboto-regular;
  font-size: 21px;
  line-height: 35px;
  b,
  &.name {
    font-family: $font-roboto-bold;
    font-weight: normal;
  }
  @include breakpoint(1024) {
    font-size: 18px;
    line-height: 1.5;
  }
  @include breakpoint(767) {
    font-size: 14px;
  }
  @include breakpoint(575) {
    width: 100%;
  }
}

.about-us-header {
  position: relative;
  display: block;
  @include breakpoint(767) {
    background-image: url('../assets/images/banner_about_us.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 120px 0 100px;
  }
  img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
    @include breakpoint(767) {
      display: none;
    }
  }

  .wrap-content {
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(calc(-50% + 30px));
    @include breakpoint(991) {
      left: 50%;
      transform: translate(-50%, calc(-50% + 30px));
      text-align: center;
      white-space: nowrap;
    }
    @include breakpoint(767) {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      transform: none;
    }
    h1 {
      font-family: $font-bold;
      font-size: 50px;
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 20px;
      @include breakpoint(1024) {
        margin-bottom: 10px;
      }
      @include breakpoint(767) {
        font-size: 30px;
      }
    }
    p {
      font-family: $font-medium;
      font-size: 33px;
      color: #fff;
      margin-bottom: 0;
      @include breakpoint(767) {
        font-size: 20px;
      }
      @include breakpoint(480) {
        font-size: 16px;
      }
    }
  }
}

.carbon-emission {
  padding: 100px 0;
  @include breakpoint(767) {
    padding: 50px 0;
  }
  h2 {
    @extend .section-content-lg;
    margin-bottom: 50px;
    @include breakpoint(767) {
      margin-bottom: 30px;
    }
    max-width: 100%;
    width: 506px;
    @include breakpoint(991) {
      width: 100%;
    }
  }
  p {
    @extend .section-content-md;
    max-width: 100%;
    width: 506px;
    @include breakpoint(991) {
      width: 100%;
    }
  }
  .list-of-information {
    padding-top: 80px;
    @include breakpoint(1024) {
      padding-top: 40px;
    }
    .information-item {
      display: flex;
      margin-bottom: 50px;
      align-items: center;
      @include breakpoint(1024) {
        margin-bottom: 25px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    img {
      width: 130px;
      height: 130px;
      @include breakpoint(1024) {
        width: 100px;
        height: 100px;
      }
      @include breakpoint(480) {
        width: 70px;
        height: 70px;
      }
    }
    p {
      @extend .section-content-md;
      width: calc(100% - 130px);
      padding-left: 35px;
      @include breakpoint(1024) {
        width: calc(100% - 100px);
      }
      @include breakpoint(991) {
        padding-left: 15px;
      }
      @include breakpoint(480) {
        width: calc(100% - 70px);
      }
    }
  }
}

.our-mission {
  background-image: url('../assets/images/banner_our_mision.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  position: relative;
  padding: 100px 0;
  @include breakpoint(767) {
    padding: 50px 0;
  }
  @include breakpoint(480) {
    background-position: top center;
  }
  h2 {
    @extend .heading-section;
    text-transform: uppercase;
    margin-bottom: 62px;
    @include breakpoint(1024) {
      margin-bottom: 25px;
    }
  }
  h3 {
    @extend .heading-section;
    font-family: $font-medium;
    margin-bottom: 62px;
    @include breakpoint(1024) {
      margin-bottom: 25px;
    }
    @include breakpoint(480) {
      font-size: 18px;
    }
  }
  p {
    font-family: $font-medium;
    font-size: 24px;
    line-height: 45px;
    margin-bottom: 34px;
    padding: 0 30px;
    @include breakpoint(1024) {
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 10px;
      padding: 0;
    }
    @include breakpoint(991) {
      font-size: 18px;
    }
    @include breakpoint(480) {
      font-size: 14px;
    }
  }
  h4 {
    @extend .section-content-lg;
  }
}

.our-values {
  padding: 100px 0;
  @include breakpoint(767) {
    padding: 50px 0;
  }
  h2 {
    @extend .heading-section;
    text-transform: uppercase;
    margin-bottom: 110px;
    text-align: center;
    @include breakpoint(1024) {
      margin-bottom: 60px;
    }
    @include breakpoint(767) {
      margin-bottom: 30px;
    }
  }
  .list-of-values {
    width: 100%;
  }
  .value-item {
    display: flex;
    align-items: center;
    margin-bottom: 110px;
    @include breakpoint(1024) {
      margin-bottom: 60px;
    }
    @include breakpoint(991) {
      margin-bottom: 40px;
      flex-direction: column;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-child(2n) {
      flex-direction: row-reverse;
      @include breakpoint(991) {
        flex-direction: column;
      }
      p {
        text-align: right;
        @include breakpoint(991) {
          text-align: left;
        }
      }
    }
    p {
      @extend .section-content-md;
      @include breakpoint(991) {
        width: 100% !important;
      }
    }
    p,
    img {
      display: block;
      max-width: 100%;
      width: 534px;
      margin: 0 auto;
    }
  }
}

.about-us {
  background-color: rgb(220, 240, 255);
  padding: 100px 0;
  @include breakpoint(767) {
    padding: 50px 0;
  }
  h2 {
    @extend .section-content-lg;
    margin-bottom: 80px;
    text-align: center;
    @include breakpoint(1024) {
      margin-bottom: 30px;
    }
    @include breakpoint(991) {
      text-align: left;
    }
  }
  p {
    @extend .section-content-md;
    padding: 0 30px;
    width: 100%;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    @include breakpoint(991) {
      text-align: left;
      padding: 0;
    }
  }
}

.our-team {
  padding: 100px 0;
  text-align: center;
  @include breakpoint(1024) {
    padding-bottom: 0;
  }
  @include breakpoint(767) {
    padding: 50px 0 0;
  }
  h2 {
    @extend .heading-section;
    text-transform: uppercase;
    margin-bottom: 100px;
    text-align: center;
    @include breakpoint(1024) {
      margin-bottom: 60px;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    width: 282px;
    display: block;
    margin: 0 auto 100px;
    border-radius: 100%;
    overflow: hidden;
    @include breakpoint(1024) {
      margin-bottom: 60px;
      width: 200px;
    }
    @include breakpoint(991) {
      margin-bottom: 40px;
    }
  }
  h3 {
    @extend .section-content-md;
    max-width: 100%;
    width: 324px;
    margin: 0 auto;
    @include breakpoint(767) {
      width: 100%;
    }
  }
  p {
    @extend .section-content-md;
    max-width: 100%;
    width: 324px;
    margin: 0 auto;
    @include breakpoint(991) {
      margin-bottom: 40px;
    }
    @include breakpoint(767) {
      width: 100%;
    }
  }
}

.signup-section {
  padding: 100px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include breakpoint(767) {
    padding: 50px 0;
  }
  h2 {
    @extend .section-content-lg;
    margin-bottom: 60px;
    text-align: center;
    width: 100%;
    @include breakpoint(1024) {
      margin-bottom: 40px;
    }
    @include breakpoint(767) {
      margin-bottom: 30px;
    }
  }
  a {
    font-family: $font-medium;
    font-size: 25px;
    padding: 10px 45px;
    margin: 0 auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background-color: rgb(99, 183, 63);
    color: #fff;
    border-radius: 8px;
    text-decoration: none !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    @include breakpoint(991) {
      font-size: 18px;
      padding: 7px 30px;
    }
  }
}
