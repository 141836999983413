// _partners.scss

#delete-account-page {
  .delete-account-header {
    background: url('../assets/images/banner-partners-mobile.jpg') no-repeat;
    background-size: cover;
    min-height: 333px;
    @include media-breakpoint-up(md) {
      background: url('../assets/images/banner-partners-desktop.jpg') no-repeat;
      background-size: cover;
      min-height: 445px;
    }
    h1 {
      margin-left: auto;
      margin-right: auto;
      font-size: 30px;
      @include media-breakpoint-up(md) {
        margin-left: auto;
        margin-right: auto;
        font-size: 45px;
      }
      text-transform: uppercase;
      color: #FFF;
      font-family: $font-bold;
    }
  }
  .delete-account-container {
    .delete-account-description {
      margin-top: 50px;
      margin-bottom: 50px;
    }
    .btn-green {
      padding-left: 50px;
      padding-right: 50px;
      margin-bottom: 50px;
    }
    .btn-danger {
      margin-bottom: 50px;
    }
    @include media-breakpoint-up(md) {
      .btn-danger {
        margin-left: 30px;
      }
    }
  }
}
