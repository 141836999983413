// _faqs.scss
.faq-header {
background: url('../assets/images/banner-faq-mobile.jpg') no-repeat;
background-size: cover;
min-height: 333px;
@include media-breakpoint-up(md) {
  background: url('../assets/images/banner-faq.jpg') no-repeat;
  background-size: cover;
  min-height: 445px;
  }
  h1 {
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  @include media-breakpoint-up(md) {
    margin-left: auto;
    margin-right: auto;
    font-size: 45px;
  }
  text-transform: uppercase;
  color: #FFF;
  font-family: $font-bold;
  }
}
.faq-description {
    margin-top: 50px;
    margin-bottom: 40px;
    p {
      margin-bottom: 10px;
    }
}
.faqs {
  .search-wrapper {
    text-align:right;
    margin-bottom: 20px;
    #search {
      outline: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px 15px;
      font-size: 16px;
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 320px;
      }
    }
  }
  .list-faq {
    margin:0px;
    padding:0px;
    .item {
      list-style-type: none;
      margin-bottom:20px;
      &:first-child {
        .description {
          display: block;
        }
      }
      .description {
         display:none;
         padding: 25px;
         background-color: #ECEBEA;
      }
      .title {
        font-family: $font-bold;
        padding: 15px 25px;
        margin-bottom:0px;
        background-color: #ECEBEA;
        cursor: pointer;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        .arrow {
          background-image: url('../assets/images/arrow-down.svg');
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          width: 14px;
          height: 8px;
        }
        &.active {
          background-color: #63B740;
          color: #FFFFFF;
          .arrow {
            background-image: url('../assets/images/arrow-up.svg');
          }
        }
      }
    }
  }
}

