// _login.scss

.sign-in-banner {
  background: url('../assets/images/Banner-sign-in-mobile.jpg') no-repeat fixed;
  background-position: center top;
  background-size: 100% auto;
  height: 240px;
  width: 100%;
  @include media-breakpoint-up(md) {
    background: url('../assets/images/Banner-sign-in-desktop.jpg') no-repeat;
    background-size: auto 100%; 
    background-position: left top;
    height: 100vh;
  }
}
.sign-in-section {
  .sign-in-wrapper {
    @include vertical-align();
    h1 {
      margin-bottom: 50px;
    }
    h5:not(.card-header) {
      text-transform: uppercase;
      font-size: $font-md;
      margin-bottom: 50px;
    }
  }
  padding-top: 30px;
  .btn-green {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .email-pass-cta {
    color: $green-color;
    margin-bottom: 20px;
  }
  p > a {
    color: $green-color;
  }
  padding-bottom: 50px;
  @include media-breakpoint-up(md) {
    height: 100vh;
  }
}
