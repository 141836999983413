// _partners.scss

.our-partners-header {
  &.partner-id-3 {
    background-image: url('../assets/images/hawaiian_legacy_mobile.jpg');
  }
  background: url('../assets/images/banner-partners-mobile.jpg') no-repeat;
  background-size: cover;
  min-height: 333px;
  @include media-breakpoint-up(md) {
    background: url('../assets/images/banner-partners-desktop.jpg') no-repeat;
    background-size: cover;
    min-height: 445px;
    &.partner-id-3 {
      background-image: url('../assets/images/hawaiian_legacy.jpg');
    }
  }
  h1 {
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
    @include media-breakpoint-up(md) {
      margin-left: auto;
      margin-right: auto;
      font-size: 45px;
    }
    text-transform: uppercase;
    color: #FFF;
    font-family: $font-bold;
  }
}
.partners-container {
  .our-partners-description {
    margin-top: 50px;
    margin-bottom: 10px;
    a {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  .partners {
    a {
      &:hover {
        color: $text-color;
        text-decoration: none;
      }
    }
    .partner-wrapper {
      padding: 20px 40px 20px 40px;
      .partner-wrapper-inner {
        border: 1px solid #E2E2E2;
        box-shadow: 6px 6px 18px 0 rgba(0,0,0,0.05);
        .partner-image {
          height: auto;
          width: auto;
          max-width: 100%;
          max-height: 100%;
        }
        .partner-description {
          white-space: pre-wrap;
          border-top: 1px solid #E2E2E2;
          padding-top: 30px;
          padding-bottom: 10px;
          min-height: 400px;
          h2.text-right {
            position: absolute;
            bottom: 15px;
            right: 35px;
          }
        }
        .partner-image-wrapper {
          display: -moz-flex;
          display: flex;
          -moz-align-items: center;
          align-items: center;
          -moz-justify-content: center;
          justify-content: center;
          height: 240px;
          padding:10px;
        }
      }
    }
  }
  .partner-detail {
    margin: 15px 0px;
    @include media-breakpoint-up(md) {
      margin: 50px 80px 10px 80px;
    }
    .partner-logo {
      @include media-breakpoint-up(md) {
        padding-right: 50px;
        padding-top: 25px;
      }
    }
    .partner-description {
      white-space: normal;
    }
  }
  .partner-images {
    .img-fluid {
      width: 100%;
    }
    @include media-breakpoint-up(md) {
      padding: 0px 50px;
    }
  }
}
