// _book_flight.scss

#book-flight {
  .book-flight-header {
    background: url('../assets/images/Banner-userprofile-update-mobile.jpg') no-repeat;
    background-size: cover;
    min-height: 333px;
    @include media-breakpoint-up(md) {
      background: url('../assets/images/Banner-userprofile-update-desktop.jpg') no-repeat;
      background-size: cover;
      min-height: 445px;
    }
    h1 {
      margin-left: auto;
      margin-right: auto;
      font-size: 30px;
      @include media-breakpoint-up(md) {
        margin-left: auto;
        margin-right: auto;
        font-size: 45px;
      }
      text-transform: uppercase;
      color: #FFF;
      font-family: $font-bold;
    }
  }
  section {
    padding-top: 30px;
    padding-bottom: 30px;
    @include media-breakpoint-up(md) {
      padding: 60px 200px 30px 200px;
    }
    h2 {
      @include media-breakpoint-up(md) {
        font-size: 30px;
      }
      font-size: $font-md;
      text-transform: uppercase;
    }
    h3 {
      font-size: $font-md;
    }
    h2, h3 {
      margin-bottom: 20px;
    }
    p {
      font-size: $font-sm;
      line-height: 32px;
    }
    form {
      margin-top: 40px;
    }
    label {
      text-transform: uppercase;
    }
    label + input,
    label + .react-datepicker-wrapper {
      margin-left: 15px;
    }
    .btn {
      box-shadow: 3px 3px 10px 0 rgba(0,0,0,0.11);
    }
    &#root {
      .departure-airport {
        margin-bottom: 15px;
      }
      #total-distance {
        margin-top: 30px;
      }
    }
    .departure-date{
      label {
        display: block;
      }
      input {
        margin-left: 0px;
        width: 100%;
      }
      .react-datepicker-wrapper {
        margin-left: 0px !important;
        width:100%;
        .react-datepicker__input-container {
          width:100%;
        }
      }
      @include media-breakpoint-up(xl) {
        text-align: left;
        .react-datepicker-wrapper {
          width:auto;
          margin-left: 10px;
        }
      }
    }
    .num-passenger {
      label {
        display: block;
      }
      input {
        margin-left: 0px;
        width: 100%;
      }
      @include media-breakpoint-up(xl) {
        text-align: right;
        input {
          width: auto;
          margin-left: 10px;
        }
      }
    }
    .round-trip {
      margin-top: 5px;
      @include media-breakpoint-up(lg) {
        margin-top: 0px;
        text-align: left;
      }
    }
    .distance {
      @include media-breakpoint-up(lg) {
        text-align: right;
      }
      span {
        margin-left: 15px;
      }

    }
    .book-flight-form {
      @include media-breakpoint-up(lg) {
        text-align: center !important;
      }
    }
    .user-profile {
      label {
        display: block;
        text-align: left;
      }
      input {
        display: block;
        margin-left: 0px;
        width: 100%;
        @include media-breakpoint-up(lg) {
          width: auto;
        }
      }
      ul {
        list-style: none;
        padding:0px;
      }
      li {
        list-style: none;
        padding:0px;
        text-align:left;
      }
    }
    .reservation_id {
      text-align: right;
      label {
        display: block;
        text-align: right;
      }
      input {
        display: block;
        float: right;
        width: 100%;
        @include media-breakpoint-up(lg) {
          width: auto;
        }
      }
    }
    .hidden {
      display: none;
    }
  }
}
