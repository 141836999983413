$font-size-title-desktop: 14px;
$font-size-content-desktop: 13px;
$font-size-desc-desktop: 11px;
$font-size-time-desktop: 18px;
$font-size-price-desktop: 30px;

$font-size-title-tablet: 13px;
$font-size-content-tablet: 13px;
$font-size-desc-tablet: 11px;
$font-size-time-tablet: 16px;
$font-size-price-tablet: 23px;

$font-size-title-mobile: 12px;
$font-size-content-mobile: 12px;
$font-size-desc-mobile: 12px;
$font-size-time-mobile: 16px;
$font-size-price-mobile: 23px;

.main-form {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: wrap;
  min-width: 800px;
  .departure-airport__menu {
    .departure-airport__option {
      white-space: nowrap;
      overflow-y: visible !important;
      min-height: auto !important;
    }
    .departure-airport__menu-list {
      > div {
        min-height: 55px;
      }
    }
  }
  .form-group {
    width: 100%;
    padding: 0 7px;
    margin-bottom: 10px;
    &.date-group {
      width: 75%;
    }
    .departure-airport__indicators {
      display: none;
    }
    .departure-airport__control {
      position: relative;
      background-image: url('../assets/images/airport.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 10px) center;
      background-size: 20px;
      padding-right: 40px;
    }
  }
  .wrap-datepicker {
    display: flex;
    align-items: center;
    justify-content: center;
    .react-datepicker-wrapper {
      flex: 1;
    }
    .format-text {
      width: 115px;
      min-width: 115px;
      text-align: center;
      margin: 0;
    }
  }
  .react-datepicker__input-container {
    position: relative;
    background-image: url('../assets/images/calendar.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 20px;
    input {
      width: 100%;
      border: 1px solid #ced4da;
      outline: none !important;
      border-radius: 0.25rem;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      line-height: 1.5;
      font-size: 1rem;
      background-color: transparent !important;
    }
  }
  .form-control {
    outline: none !important;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin: 0 !important;
    box-shadow: none !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .wrap-input {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .wrap-message {
    margin-bottom: 15px;
    .form-message-error {
      padding-top: 0;
    }
    &:empty {
      margin-bottom: 0;
    }
  }
  .form-required {
    color: #f05248;
  }
  .departure-airport {
    margin-bottom: 0 !important;
  }
  .form-message-error {
    color: #f05248;
    padding-top: 10px;
    text-align: center;
    width: 100%;
    font-size: 13px;
  }
  .line-border {
    border-top: 1px solid #ced4da;
    padding: 25px 15px;
    align-items: center;
    justify-content: center;
  }
  .search-button {
    border: 1px solid #ced4da;
    background-color: #63B740;
    border-radius: 4px;
    height: 38px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    color: #fff;
    outline: none !important;
    position: relative;
    min-width: 100px;
  }
  .checkbox-title {
    padding-left: 10px;
  }
  .group-city {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .item-city {
    padding: 15px 0;
    background-color: rgba(186, 218, 245, .8);
    border-radius: 4px;
    margin-bottom: 15px;
    position: relative;
    flex-wrap: nowrap;
    justify-content: center;
    &:nth-child(2n + 1) {
      background-color: rgba(186, 245, 237, .8)
    }
  }
  .react-datepicker-wrapper {
    background-color: #fff;
    border-radius: 0.25rem;
    width: 140px;
    min-width: 140px;
  }
  .btn-add-new-city {
    border: 1px solid #ced4da;
    background-color: #63B740;
    border-radius: 4px;
    height: 38px;
    line-height: 38px;
    padding: 0 25px;
    margin: 0;
    color: #fff;
    outline: none !important;
    margin-bottom: 10px;
  }
  .wrap-btn-add-new-city {
    text-align: right;
    padding-right: 15px;
    width: 100%;
  }
  .btn-remove {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 20px;
    background-color: #63B740;
    color: #fff;
    border: none;
    outline: none;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
}

.flight-info,
.item-rightside {
  display: flex;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: none;
  margin-bottom: 20px;
  border-radius: 4px;
  .flight-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .flight-cost {
    width: 180px;
    min-width: 180px;
    position: relative;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-left: 1px dashed #ccdef0;
    }
  }
  .flight-price {
    .__react_component_tooltip {
      min-width: 280px;
      padding: 10px;
      opacity: 1;
    }
  }
  .ticket-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    line-height: 18px !important;
    font-size: 14px !important;
    padding: 0;
  }
  .price-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 13px !important;
    line-height: 20px !important;
    color: #6b8299;
    padding: 0;
  }
  .total-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px dashed #ccdef0;
    font-size: 16px;
    font-weight: 700;
  }
  .flight-item {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 20px;
      border-bottom: 1px dashed #ccdef0;
    }
    &:last-child() {
      padding: 0;
      margin: 0;
      &:before {
        display: none
      }
    }
  }
  .flight-time {
    width: 250px;
    min-width: 250px;
    .wrap-time {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .operated-by-airlines {
      display: block;
      text-align: center;
      font-size: $font-size-content-desktop;
    }
    .text-time {
      font-size: $font-size-time-desktop;
      font-weight: 700;
      cursor: pointer;
      sup {
        font-size: 14px;
        top: -14px;
      }
    }
    .short-line {
      height: 3px;
      width: 14px;
      background-color: #212529;
      margin: 5px 5px 0;
    }
  }
  .list-stops-wrap {
    width: 20%;
    text-align: center;
    .number-of-stops {
      font-size: $font-size-title-desktop;
      font-weight: 700;
      padding: 0;
      line-height: 20px;
      margin-bottom: 5px;
      display: block;
    }
    .stop-code {
      color: #647582;
      font-size: $font-size-content-desktop;
    }
  }
  .flight-duration {
    width: 20%;
    text-align: center;
    .duration-value {
      font-size: $font-size-title-desktop;
      font-weight: 700;
      padding: 0;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .flight-code {
      color: #647582;
      font-size: $font-size-content-desktop;
    }
    .short-line {
      height: 2px;
      width: 7px;
      background-color: #647582;
      margin: 0 5px;
    }
    .trip-value {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .total-price-ticket {
    font-size: 30px;
    font-weight: 700;
    cursor: pointer;
  }
}

.list-options {
  pointer-events: auto;
  min-width: 180px;
  padding: 0;
  margin: 0;
  list-style: none;
  .options {
    font-size: 14px;
    padding: 5px 15px;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #fafbfc!important;
    }
    &.active {
      font-weight: 700;
      background-color: #fafbfc!important;
    }
  }
}

.wrap-input-tooltip {
  .__react_component_tooltip {
    opacity: 1 !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: 0 0 3px 1px rgba(0,0,0,0.15);
    &:before,
    &:after {
      display: none;
    }
  }
  .input-value {
    min-width: 180px;
    display: block;
    padding: 5px 15px;
    .value {
      position: relative;
      &:after {
        content: '';
        width: 10px;
        height: 10px;
        border-top: 2px solid #ced4da;
        border-right: 2px solid #ced4da;
        position: absolute;
        right: -25px;
        top: 7px;
        transform: rotate(135deg);
        border-radius: 2px;
      }
    }
  }
}

.wrap-flight {
  max-width: 100%;
  margin: 0 auto;
  cursor: pointer;
  transition: all .2s;
  border-radius: 4px;
  &:hover {
    box-shadow: 0 2px 7px rgba(0,0,0,0.2)
  }
}

.wrap-input-tooltip {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 10px;
  .input-select-top {
    min-width: 180px;
    margin-right: 15px;
    padding-right: 15px;
    position: relative;
    .wrap-input-tooltip {
      padding-bottom: 0;
    }
    &:last-child {
      margin: 0;
      padding-right: 0;
      &:after {
        display: none;
      }
    }
    &:after {
      content: '';
      background-color: #ced4da;
      width: 1px;
      position: absolute;
      right: 0;
      top: 10px;
      bottom: 10px;
    }
  }
  .departure-airport__control  {
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .departure-airport__single-value {
    cursor: pointer;
    overflow: visible;
    &:after {
      content: '';
      width: 10px;
      height: 10px;
      border-top: 2px solid #ced4da;
      border-right: 2px solid #ced4da;
      position: absolute;
      right: -25px;
      top: 7px;
      transform: rotate(135deg);
      border-radius: 2px;
    }
  }
  .departure-airport__indicators {
    display: none;
  }
}

.datepicker-group,
.input-select-time {
  width: 170px;
  max-width: 170px;
  min-width: 170px;
  padding: 0 15px;
}

.item-input-number {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  .wrap-input-number {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .btn-minus {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    background-image: url('../assets/images/minus-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px auto;
    outline: none;
    &:disabled {
      opacity: 0.5;
    }
  }
  .btn-plus {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    background-image: url('../assets/images/plus-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px auto;
    outline: none;
    &:disabled {
      opacity: 0.5;
    }
  }
  .input-number-value {
    min-width: 20px;
    text-align: center;
  }
  .input-number-label {
    text-transform: none !important;
    font-size: 14px;
    margin: 0;
  }
}

.wrap-input-tooltip {
  .list-options {
    padding: 10px 10px 0;
  }
}

.flight-code,
.stop-code {
  cursor: pointer;
}

.carbon-emissions-wrap {
  width: 35%;
  .carbon-emissions-title {
    font-size: $font-size-title-desktop;
    font-weight: 700;
    padding: 0;
    line-height: 20px;
    margin-bottom: 5px;
    text-align: center;
  }
  .carbon-emissions-value {
    color: #647582;
    font-size: $font-size-content-desktop;
    text-align: center;
  }
}

.wrap-btn-viewmore {
  text-align: center;
}

.btn-viewmore {
  border: 1px solid #ced4da;
  background-color: #63B740;
  border-radius: 4px;
  height: 38px;
  padding: 0 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: #fff;
  outline: none !important;
  position: relative;
  min-width: 100px;
}

#book-flight .book-flight-header {
  display: none !important;
}

#book-flight section {
  padding: 0;
}

#book-flight {
  .form-label {
    text-transform: initial;
    font-size: 13px;
    margin-bottom: 0;
  }
  section form {
    margin: 0 !important;
  }
}

.wrap-book-flight-form {
  width: 100%;
  .onetravel-acknowledgement {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(20, 37, 52, .8);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 10px 20px;
    .item-avatar {
      display: inline-block;
      max-width: 100px;
      height: auto;
      margin-right: 20px;
    }
  }
}

.search-fluid {
  background-image: url('../assets/images/Banner-userprofile-update-desktop.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .search-flight-container {
    padding-top: 130px;
    padding-bottom: 30px;
    .title-book-flights {
      text-align: center;
      font-size: 45px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 15px;
    }
    .search-flight-form {
      padding: 15px;
      background-color: rgba(255,255,255,0.9);
      border-radius: 6px;
      box-shadow: 0 5px 10px rgba(0,0,0,0.15);
    }
  }
  .wrap-input-tooltip .departure-airport__control {
    background-color: transparent !important;
  }
}

.wrap-inputs-search {
  width: 100%;
  display: flex;
  flex-direction: column;
  .item-city {
    padding: 10px 0;
    border-radius: 0;
    position: relative;
    margin-bottom: 0;
    background-color: transparent !important;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 7px;
      right: 7px;
      border-top: 1px dashed #63B740;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.wrap-input-submit {
  position: relative;
  width: 100%;
  .search-button {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -5px);
    border-radius: 100px;
    border: none;
    box-shadow: 0 2px 5px rgba(0,0,0,0.5);
  }
}

.search-results {
  padding-top: 25px;
  padding-bottom: 25px;
  &:empty {
    padding: 0;
  }
}

.service-provided {
  font-size: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0 !important;
  .img-provided {
    width: 60px;
    margin-left: 5px;
    background-color: rgba(0,0,0,0.5);
    padding: 5px;
    border-radius: 4px;
  }
}

.filter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .title-filter {
    width: 100%;
    text-align: center;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 700;
  }
}

.btn-sort {
  border: none;
  background: #63B740;
  height: auto;
  margin: 10px;
  border-radius: 4px;
  color: #fff;
  outline: none !important;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 15px;
  &.active {
    background-color: #345824;
  }
}

.best-option-wrap,
.best-option-time {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.filter-container {
  .input-select-top {
    width: 280px;
    max-width: 100%;
    margin-top: 10px;
  }
}

.breadcrumbs-item {
  height: 36px;
  line-height: 36px;
  &.item-selected {
    height: 36px !important;
    line-height: 36px !important;
    overflow: hidden;
    .breadcrumbs-content {
      cursor: pointer;
      line-height: 36px;
      transition: all .3s ease-in-out;
      display: block;
      &:hover {
        transform: translateY(-36px);
      }
    }
  }
}

.breadcrumbs-tooltip {
  max-width: calc(100vw - 40px);
  .breadcrumbs-tooltip-content {
    display: block;
    font-weight: 400;
    line-height: 25px;
    font-size: 13px;
    color: #000;
    &.content-time {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.breadcrumbs-wrap {
  padding: 0 15px;
  .flights-selecting-title {
    margin-bottom: 0 !important;
    padding-top: 20px;
  }
}

.flight-breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .item-selected {
    padding-right: 30px;
    margin-right: 15px;
    position: relative;
    color: #63B740;
    font-weight: 700;
    &:after {
      content: '';
      width: 11px;
      height: 11px;
      transform: rotate(135deg);
      border-left: 2px solid #ccc;
      border-top: 2px solid #ccc;
      display: block;
      position: absolute;
      top: 13px;
      right: 5px;
    }
    .__react_component_tooltip {
      box-shadow: 0 5px 10px rgba(0,0,0,0.3);
      opacity: 1 !important;
    }
  }
}

.btn-book-flight,
.btn-choose-another-flight {
  border: 1px solid #ced4da;
  background-color: #63B740;
  border-radius: 4px;
  height: 38px;
  padding: 0 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: #fff;
  outline: none !important;
  position: relative;
  min-width: 100px;
}

.booking-item {
  background-color: rgba(20, 37, 52, .8);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 10px 20px;
  .item-avatar {
    display: inline-block;
    max-width: 100px;
    height: auto;
    margin-right: 20px;
  }
  .item-price {
    font-size: 30px;
    margin-right: 20px;
  }
  .__react_component_tooltip {
    min-width: 280px;
    padding: 10px;
    opacity: 1;
  }
  .item-rightside,
  .item-leftside {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    border: none;
    border-radius: none;
    .item-price {
      cursor: pointer;
    }
  }
}

.trip-infor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .trip-infor-rightside {
    text-align: right;
  }
}

.wrap-button-back {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.title-booking-options,
.title-flight-segments-selected {
  display: block;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 700;
  &:empty {
    margin-bottom: 0;
  }
}

.total-price-title,
.trip-wrap {
  display: block;
  font-size: 15px;
  font-weight: 700;
}

.trip-type,
.total-price-value {
  font-size: 30px;
  font-weight: 700;
}

.content-loader-mobile {
  display: none;
  padding-bottom: 30px;
}
.content-loader {
  padding-bottom: 30px;
}

.sub-title {
  font-size: $font-size-desc-desktop;
  color: #647582;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: '('
  }
  &:after {
    content: ')'
  }
}

.wrap-datepicker {
  .DateInput_input {
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding: 0 11px;
    line-height: 34px;
    font-weight: normal;
    background-color: transparent !important;
  }
  .DateInput {
    min-width: 100px;
    width: calc(50% - 1px);
    background-color: transparent !important;
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-size: 13px;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      font-size: 13px;
    }
    :-ms-input-placeholder { /* IE 10+ */
      font-size: 13px;
    }
    :-moz-placeholder { /* Firefox 18- */
      font-size: 13px;
    }
    &:last-of-type {
      .DateInput_input_1 {
        padding-right: 40px;
        background-image: url('../assets/images/calendar.png');
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) center;
        background-size: 20px;
      }
    }
  }
  .DateRangePickerInput_arrow_svg {
    width: 17px;
    height: 17px;
  }
  .DateInput_input__focused {
    background-color: transparent;
  }
  .DayPickerKeyboardShortcuts_show {
    display: none;
  }
  .DateRangePickerInput__withBorder,
  .SingleDatePickerInput__withBorder {
    border-radius: 4px;
    border-color: hsl(0,0%,80%);
  }
  .DateRangePickerInput_arrow {
    position: relative;
    .DateRangePickerInput_arrow_svg {
      display: none;
    }
  }
  .DateRangePickerInput_arrow:after {
    content: '';
    top: 0;
    left: 0;
    width: 1px;
    height: 20px;
    bottom: 0;
    background-color: #ced4da;
    position: absolute;
    transform: translateY(-50%);
  }
  .DateRangePicker,
  .DateRangePickerInput {
    width: 100%;
  }
  .SingleDatePicker,
  .SingleDatePickerInput {
    width: 100%;
    .DateInput {
      width: 100%;
    }
    .DateInput_input__focused {
      padding-right: 40px;
      background-image: url('../assets/images/calendar.png');
      background-repeat: no-repeat;
      background-position: calc(100% - 10px) center;
      background-size: 20px;
    }
  }
}
.search-flight-form {
  .departure-airport__placeholder {
    font-size: 13px !important
  }
}

.back-container {
  width: 100%;
  margin-bottom: 10px;
  .btn-back {
    width: 32px;
    height: 32px;
    border: 1px solid rgba(0,0,0,0.7);
    border-radius: 100px;
    background-image: url('../assets/images/previous.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 16px;
    background-color: #fff;
    outline: none;
    transition: all .2s;
    &:hover {
      box-shadow: 0 0 5px 1px rgba(0,0,0,0.3);
    }
  }
}

.wrap-filter-by-stops {
  .filter-title {
    border: none;
    background: #63B740;
    height: auto;
    margin: 10px;
    border-radius: 4px;
    color: #fff;
    outline: none !important;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px 30px 5px 15px;
    &:after {
      content: '';
      width: 10px;
      height: 10px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      position: absolute;
      right: 10px;
      top: 10px;
      transform: rotate(135deg);
      border-radius: 2px;
    }
  }
  .__react_component_tooltip {
    opacity: 1;
    box-shadow: 0 1px 5px rgba(0,0,0,0.3);
    padding: 15px;
    &:before,
    &:after {
      display: none;
    }
  }
  .filter-option {
    position: relative;
    padding: 5px 0;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .input-option {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 20px;
      top: 5px;
      left: 0;
      cursor: pointer;
      &:checked ~ .option-mask {
        &:before {
          background-color: #63B740;
        }
      }
      &:disabled ~ .option-mask,
      &:disabled ~ .option-title,
      &:disabled {
        opacity: 0.3;
        cursor: no-drop;
      }
    }
    .option-mask {
      width: 18px;
      height: 18px;
      border: 1px solid #63B740;
      border-radius: 100px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &:before {
        content: '';
        width: 10px;
        border-radius: 20px;
        height: 10px;
        display: block;
        background-color: #fff;
      }
    }
    .option-title {
      margin-left: 5px;
    }
  }
}

.flight-logo {
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  .img-logo {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 1199px) {
  .main-form {
    min-width: auto;
  }
  .wrap-input-tooltip {
    flex-wrap: wrap;
    .input-select-top {
      min-width: 145px;
      font-size: 14px;
      .wrap-input-tooltip {
        padding: 0;
        .input-value {
          min-width: 140px;
        }
      }
    }
  }
  .checkbox-wrap {
    margin-left: 10px;
  }
  .checkbox-title {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
  .flight-logo {
    max-width: 90px;
  }
  .main-form {
    min-width: auto;
  }
  .form-group.range-picker {
    width: 100% !important;
  }
  .wrap-input-tooltip {
    flex-wrap: wrap;
  }
  .checkbox-wrap {
    margin-left: 10px;
  }
  .wrap-inputs-search.round-trip {
    .item-city {
      flex-wrap: wrap;
    }
    .form-group {
      width: 50%;
    }
  }
  .departure-airport__placeholder {
    font-size: 14px;
  }
  .breadcrumbs-wrap {
    padding: 0;
  }
  .flight-info {
    .flight-time {
      min-width: 200px;
      width: 200px;
      .short-line {
        height: 3px;
        width: 8px;
        margin: 2px 5px 0;
      }
      .text-time {
        font-size: $font-size-time-tablet;
      }
      .operated-by-airlines {
        font-size: $font-size-content-tablet;
      }
    }
    .list-stops-wrap {
      .stop-code {
        font-size: $font-size-content-tablet
      }
      .number-of-stops {
        font-size: $font-size-title-tablet;
      }
    }
    .flight-duration {
      .duration-value {
        font-size: $font-size-content-tablet
      }
      .flight-code {
        font-size: $font-size-title-tablet;
        line-height: 20px;
      }
      .short-line {
        height: 1px;
        width: 5px;
      }
    }
    .carbon-emissions-wrap {
      width: 30%;
      .carbon-emissions-title {
        font-size: $font-size-content-tablet
      }
      .carbon-emissions-value {
        font-size: $font-size-title-tablet;
        line-height: 20px;
      }
    }
    .flight-cost {
      width: 90px;
      min-width: 90px;
      .total-price-ticket {
        font-size: $font-size-price-tablet;
      }
    }
  }
  .flights-selecting-title {
    font-size: 15px !important;
  }
  .search-results {
    padding: 15px;
    .search-results {
      padding: 15px 0;
    }
  }
  .breadcrumbs-content {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .flight-logo {
    margin-bottom: 10px;
  }
  .content-loader-mobile {
    display: block;
  }
  .content-loader {
    display: none;
  }
  .main-form {
    min-width: auto;
    margin: 0;
    margin-top: 0 !important;
    .item-city {
      flex-wrap: wrap;
    }
  }
  #book-flight section h2 {
    padding: 0 15px;
    justify-content: center;
  }
  .wrap-inputs-search  {
    flex-wrap: wrap;
  }

  .search-fluid .search-flight-container {
    padding-top: 70px;
    padding-bottom: 30px;
  }
  .wrap-input-submit {
    width: 100%;
    margin: 0;
    padding-top: 0;
  }
  .wrap-btn-add-new-city {
    text-align: left;
    padding-left: 7px;
    .btn-add-new-city {
      background-color: transparent;
      color: #63B740;
      border: none;
      height: auto;
    }
  }
  .main-form .search-button {
    margin: 0 auto 5px;
  }
  .wrap-input-tooltip .input-select-top .wrap-input-tooltip {
    padding-left: 5px;
    line-height: 28px;
  }
  .list-stops-wrap,
  .flight-duration,
  .carbon-emissions-wrap {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .flight-info {
    flex-wrap: wrap;
    .flight-cost {
      padding-left: 0;
      padding-top: 10px;
      width: 100%;
      &:before {
        top: 0;
        left: 0;
        bottom: auto;
        right: 0;
        border-left: none;
        border-top: 1px dashed #ccdef0;
      }
    }
  }
  .best-option-wrap {
    font-size: 13px;
    line-height: 18px;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    .best-option-time {
      align-items: flex-start;
    }
    .best-option-price {
      padding-right: 20px;
    }
  }
  .booking-item {
    .item-leftside {
      font-size: 10px;
      .item-avatar {
        max-width: 70px;
      }
    }
    .item-rightside {
      .item-price {
        font-size: 25px;
      }
    }
  }
  .btn-book-flight {
    font-size: 13px;
    padding: 0 15px;
  }
  .trip-type,
  .total-price-value {
    font-size: 25px;
  }
  .form-group {
    width: 100% !important;
  }
  .flight-item {
    flex-wrap: wrap;
    justify-content: center;
    .flight-time {
      width: 100%;
      margin-bottom: 5px;
    }
    .list-stops-wrap {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-bottom: 5px;
      .number-of-stops {
        margin-bottom: 0;
      }
      .list-stops {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &:before {
          content: ':';
          margin-right: 5px;
          font-weight: 700;
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .wrap-datepicker .DateInput_input {
    font-size: 13px;
  }
  .sub-title {
    font-size: $font-size-desc-mobile;
    color: #212529;
    font-weight: 700;
    padding-left: 5px;
    &:before,
    &:after {
      display: none;
    }
  }
  .text-time {
    font-size: $font-size-time-mobile !important;
  }
  .flight-info {
    padding: 10px;
    margin-bottom: 10px;
  }
  .wrap-input-tooltip {
    .input-value {
      padding: 5px;
    }
  }
  .booking-item {
    flex-direction: column-reverse;
    .item-leftside {
      flex-direction: row-reverse;
      .item-avatar {
        margin-right: 0;
        margin-left: 5px;
      }
    }
    .item-rightside {
      flex-direction: column;
      .item-price {
        margin: 0 0 10px;
      }
      .btn-book-flight {
        margin-bottom: 10px;
      }
    }
  }

  .trip-infor {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .trip-wrap {
      font-size: 13px;
    }
    .trip-infor-rightside {
      display: flex;
      align-items: flex-end;
      .total-price-title,
      .total-price-value {
        font-size: 18px;
      }
      .total-price-title {
        display: flex;
        text-align: center;
        justify-content: center;
        &:after {
          content: ':';
          margin-right: 5px;
        }
      }
    }
  }
  .title-booking-options,
  .title-flight-segments-selected {
    font-size: 13px;
  }

  .flight-duration {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .duration-value {
      margin-bottom: 0 !important;
      &:after {
        content: '*';
        margin: 0 5px;
      }
    }
  }
  .carbon-emissions-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .carbon-emissions-title {
      margin-bottom: 0 !important;
      font-size: $font-size-title-mobile !important;
      .sub-title {
        font-size: $font-size-desc-mobile;
      }
      br {
        display: none;
      }
      &:after {
        content: ':';
        margin-right: 5px;
      }
    }
  }
  .flight-info .list-stops-wrap .stop-code,
  .flight-info .list-stops-wrap .number-of-stops,
  .flight-info .flight-duration .duration-value,
  .flight-info .flight-duration .flight-code,
  .flight-info .carbon-emissions-wrap .carbon-emissions-title,
  .flight-info .carbon-emissions-wrap .carbon-emissions-value {
    color: #212529;
    font-weight: 700;
    line-height: 20px;
    &:before,
    &:after {
      line-height: 20px;
    }
  }
  .breadcrumbs-item {
    height: auto;
    line-height: 20px;
  }
  .wrap-btn-add-new-city {
    padding: 0 5px !important;
    .btn-add-new-city {
      padding: 0 !important;
    }
  }
  .onetravel-acknowledgement {
    flex-direction: column;
    text-align: center;
    max-width: calc(100vw - 30px);
  }
}
