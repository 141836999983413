// _profile.scss

.member-profile-header {
  background: url('../assets/images/Banner-member-profile-mobile.jpg') no-repeat;
  background-size: cover;
  min-height: 333px;
  @include media-breakpoint-up(md) {
    background: url('../assets/images/Banner-member-profile-desktop.jpg') no-repeat;
    background-size: cover;
    min-height: 445px;
  }
  h1 {
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
    @include media-breakpoint-up(md) {
      margin-left: auto;
      margin-right: auto;
      font-size: 45px;
    }
    text-transform: uppercase;
    color: #FFF;
    font-family: $font-bold;
  }
}
.member-profile-body {
  background-color: #E2E2E2;
  .member-profile-content {
    .profile-img {
      margin: 55px auto 30px auto;
      @include media-breakpoint-up(md) {
        margin: 33px auto 25px auto;
      }
      width: 235px;
      height: 235px;
    }
    .choose-organization {
      margin-top: 20px;
      margin-bottom: 30px;
      h4 {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .btn-change-organization,
      .btn-update-profile {
        color: $green-color;
        text-decoration: underline;
      }
    }
    .donations {
      @include media-breakpoint-up(lg) {
        border: 1px solid #4A3730;
      }
      padding: 10px;
      margin-bottom: 20px;
      span {
        color: $green-color;
      }
    }
    .list-flights {
      overflow-x: scroll;
      .mobile-note {
        font-size: 12px;
        color: $gray-darker;
      }
      @include media-breakpoint-up(md) {
        overflow-x: auto;
        .mobile-note {
          display: none;
        }
      }

    }
    a.btn {
      margin-top: 20px;
      margin-bottom: 20px;
    
    }
  }
  .partner-option {
    margin-top: 15px;
  }
  .btn-change-organization {
    margin: 30px auto 30px auto;
  }
  .btn-go-back {
    margin: 30px auto 30px 30px;
  }
}
