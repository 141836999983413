// _contact.scss

#contact-us {
  .contact-us-header {
    background: url('../assets/images/Banner-contact-us-mobile.jpg') no-repeat;
    background-size: cover;
    min-height: 333px;
    @include media-breakpoint-up(md) {
      background: url('../assets/images/Banner-contact-us-desktop.jpg') no-repeat;
      background-size: cover;
      min-height: 445px;
    }
    h1 {
      margin-left: auto;
      margin-right: auto;
      font-size: 30px;
      @include media-breakpoint-up(md) {
        margin-left: auto;
        margin-right: auto;
        font-size: 45px;
      }
      text-transform: uppercase;
      color: #FFF;
      font-family: $font-bold;
    }
  }
  section {
    padding-top: 30px;
    padding-bottom: 30px;
    @include media-breakpoint-up(md) {
      padding-top: 60px;
    }
    h2 {
      @include media-breakpoint-up(md) {
        font-size: 30px;
      }
      font-size: $font-md;
      text-transform: uppercase;
    }
    h3 {
      font-size: $font-md;
    }
    h2, h3 {
      margin-bottom: 20px;
    }
    p {
      font-size: $font-sm;
      line-height: 32px;
    }
    form {
      margin-top: 40px;
    }
    label {
      font-family: $font-bold;
      @include media-breakpoint-up(md) {
        font-size: $font-lg;
      }
      font-size: $font-sm;
    }
    .btn-green {
      box-shadow: 3px 3px 10px 0 rgba(0,0,0,0.11);
      width: 200px;
      height: 50px;
    }
  }
  section.get-in-touch {
    .address {
      @include media-breakpoint-up(md) {
        margin-top: 10px;
      }
    }
    .address img, .email img {
      float: left;
      margin-right: 10px;
    }
    .address, .email, .social {
      margin-bottom: 50px;
    }
    hr {
      margin-bottom: 50px;
    }
    .social {
      a {
        margin-right: 20px;
      }
    }
    .invalid-feedback {
      // Necessary because the input is not 
      // wrapped in .form-control class.
      display: inline;
    }
  }
  section.blog-subscribe {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: $grey-background-color;
    .form-group {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
    input.emailinput {
      @include media-breakpoint-up(md) {
        width: 400px;
        margin-right: 20px;
      }
    }
  }
}
