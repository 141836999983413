@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "variables-overrides.scss";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/hamburgers/_sass/hamburgers/hamburgers";
@import "../node_modules/progress-tracker/src/styles/progress-tracker.scss";
@import "../node_modules/pretty-checkbox/src/pretty-checkbox.scss";
@import "../node_modules/select2/dist/css/select2.css";
@import "../node_modules/select2-bootstrap4-theme/src/select2-bootstrap4.scss";
@import 'mixins/flexbox';
@import 'mixins/breakpoint';
@import "selectize.bootstrap4";
@import "aspect_ratio";
@import 'theme';
@import 'navbar';
@import 'home';
@import 'login';
@import 'signup';
@import 'partners';
@import 'delete_account';
@import 'manage_partners';
@import 'about';
@import 'legal';
@import 'contact';
@import 'footer';
@import 'profile';
@import 'userprofile_update';
@import 'add_flight';
@import 'book_flight';
@import 'faqs';
@import 'search-flight-form';
@import 'concur_landingpage';

// TODO: Delete unused css.
// $accent-color: #306EE4;
// $dark-grey: #444444;
// $green-color: #62b731;
// $navbar-color: rgb(62, 185, 248);
// $grey: dimgrey;
// $light-grey: silver;
//
// html {
//   font-size: 0.55rem;
// }
// .messages {
//   position: fixed;
//   margin-top: 10%;
//   z-index: 1;
//   width: 90%;
//   left: 50%;
//   transform: translateX(-50%);
//   .message {
//     margin-bottom: 20px;
//
//   }
//   @include media-breakpoint-down(sm) {
//     margin-top: 20%;
//     .message {
//       font-size: 2rem;
//       span {
//         font-size: 2.5rem;
//       }
//     }
//   }
// }
//
// @include media-breakpoint-up(md) {
//   html {
//     font-size: 1rem;
//   }
// }
// @include media-breakpoint-down(sm) {
//   .navbar a {
//     font-size: 2.25rem;
//   }
//   label {
//     font-size: 1.5rem;
//   }
//   .form-control {
//     font-size: 2rem;
//   }
// }
//
// body {
//   background-color: snow;
// }
// a.btn, input.btn {
//     font-family: $font-bold;
//     border-radius: 34px;
//     text-transform: uppercase;
//     @include media-breakpoint-up(md) {
//       font-size: 1.5rem;
//     }
//     letter-spacing: 0;
//   &.cta-btn {
//     background: $accent-color;
//     border-color: $accent-color;
//     color: #FFFFFF;
//   }
// }
// .material-icons {
//   &.green {
//     color: $green-color;
//   }
//   &.blue {
//     color: $accent-color;
//   }
//   &.grey {
//     color: $grey;
//   }
//
// }
// .icon-lg {
//   font-size: 36px;
// }
// .icon-xxl {
//   font-size: 225px;
//   @include media-breakpoint-down(md) {
//     font-size: 150px;
//   }
// }
//
// body:not(#home) {
//   .navbar {
//     background-color: $navbar-color;
//   }
// }
// // test
// .hamburger:not(.is-active) .hamburger-inner::before {
//   width: 30px;
//   right: 0;
// }
// .hamburger:not(.is-active) .hamburger-inner::after {
//   width: 20px;
//   right: 0;
// }
// .navbar-dark .navbar-nav .nav-link {
//   color: rgba(#FFF, 0.80);
//   font-weight: bold;
// }
// .navbar-dark .navbar-nav .nav-link:hover,
// .navbar-dark .navbar-nav .nav-link:focus {
//   color: rgba(#FFF, 1);
//   font-weight: bold;
// }
// @media (max-width: 992px) { //bootstrap 4 collapses navbar at 768px
//   .navbar {
//     background-color: $navbar-color;
//     //position: absolute;
//     //width: 100%;
//     //z-index: 9999;
//     //right: 0;
//     //top: 0;
//   }
// }
// .banner {
//   //@include media-breakpoint-up(sm) {
//   //  margin-top: -62px;
//   //}
//   background: url("../images/jsos_banner_bg.jpg") no-repeat center bottom;
//   background-size: cover;
//   min-height: 625px;
//   @include media-breakpoint-down(sm) {
//     min-height: 280px;
//   }
//   position: relative;
//   top: 0;
// }
// #home-cta-btn {
//   p {
//     width: 350px;
//     color: #FFF;
//     @include media-breakpoint-down(sm) {
//       font-size: 1.5rem;
//     }
//   }
//   text-align: center;
//   position: absolute;
//   top: 75%;
//   @include media-breakpoint-up(md) {
//     top: 70%;
//   }
//   left: 50%;
//   transform: translate(-50%, -50%);
//   a.btn {
//     width: 231px;
//     height: 56px;
//     @include media-breakpoint-down(sm) {
//       width: 200px;
//       height: 42px;
//       font-size: 2.25rem;
//     }
//   }
// }
// .body-wrapper {
//   margin-top: 68px;
// }
// section {
//   padding: 40px;
// }
// .cta-text {
//   background-color: $green-color;
//   h1 {
//     color: white;
//   }
// }
// .cta-text-2 {
//   h2 {
//     //color: $accent-color;
//     color: white;
//   }
//   //background-color: rgb(204, 238, 251);
//   background-color: $navbar-color;
// }
// .how-it-works, .our-partners {
//   h2 {
//     padding-top: 30px;
//   }
//   h4 {
//     text-align: left;
//     margin-left: 30px;
//   }
//   a.btn {
//     padding: 12px 77px;
//     margin-left: 30px;
//     margin-right: 30px;
//     margin-top: 60px;
//     margin-bottom: 200px;
//     white-space:normal;
//     h4 {
//       font-family: $font-bold;
//       color: white;
//       padding: 0;
//       margin: 0;
//     }
//   }
// }
// #about-page {
//   background-color: $navbar-color;
//   h1, h3, h6 {
//     color: white;
//   }
//   h1 {
//     padding-top: 150px;
//     padding-bottom: 50px;
//   }
//   h3 {
//     padding-bottom: 30px;
//     line-height: 1.5;
//   }
//   footer {
//     padding-top: 150px;
//   }
// }
//
// #signup, #partner-signup {
//   label {
//     color: white;
//   }
//   section {
//     padding: 20px;
//   }
//   background-color: $navbar-color;
//   h1, h3, h6 {
//     color: white;
//   }
//   h1 {
//     padding-top: 150px;
//   }
//   h3 {
//     padding-bottom: 30px;
//     line-height: 1.5;
//   }
//   footer {
//     padding-top: 150px;
//   }
// }
