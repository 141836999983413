/* Fonts */

@font-face {
  font-family: "Roboto Condensed Bold";
  src: url('../assets/fonts/RobotoCondensed-Bold.ttf');
}
@font-face {
  font-family: "Roboto Bold";
  src: url('../assets/fonts/Roboto-Bold.ttf');
}
@font-face {
  font-family: "Roboto Regular";
  src: url('../assets/fonts/Roboto-Regular.ttf');
}
@font-face {
  font-family: "Roboto Light";
  src: url('../assets/fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: "TitilliumWeb Regular";
  src: url('../assets/fonts/TitilliumWeb-Regular.ttf');
}
@font-face {
  font-family: "TitilliumWeb Bold";
  src: url('../assets/fonts/TitilliumWeb-Bold.ttf');
}
@font-face {
  font-family: "Montserrat Bold";
  src: url('../assets/fonts/Montserrat-Bold.ttf');
}
@font-face {
  font-family: "Montserrat Regular";
  src: url('../assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: "Montserrat SemiBold";
  src: url('../assets/fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: "Roboto Medium";
  src: url('../assets/fonts/Roboto-Medium.ttf');
}

$font-roboto-bold: 'Roboto Bold', sans-serif;
$font-roboto-regular: 'Roboto Regular', sans-serif;
$font-roboto-light: 'Roboto Light', sans-serif;
$font-logo: 'Roboto Condensed Bold', sans-serif;
$font-bold: 'Montserrat Bold', sans-serif;
$font-regular: 'TitilliumWeb Regular', sans-serif;
$font-regular-bold: 'TitilliumWeb Bold', sans-serif;
$font-highlight: 'Montserrat Regular', sans-serif;
$font-semibold: 'Montserrat SemiBold', sans-serif;
$font-medium: 'Roboto Medium', sans-serif;
//// Glyphicons
//$icon-font-path: "../../bower_components/bootstrap/fonts/";
$font-xl: 48px;
$font-lg: 22px;
$font-md: 18px;
$font-sm: 16px;
$font-xs: 14px;


/* Colors */

$green-color: #63B740;
$text-color: #4A3730;
$navbar-color: rgb(71, 155, 225);
$grey-color: #ADA5A2;
$grey-background-color: #ECEBEA;

$brand-primary:        #1da5ff;
// $brand-success:         #5cb85c;
// $brand-info:            #5bc0de;
// $brand-warning:         #f0ad4e;
$brand-danger:         #e05540;

// $gray-base:         #000;
$gray-darker:          #515365;
$gray-dark:            #7b818d;
$gray:                 #6b7c93;
$gray-light:           #9b9b9b;
$gray-lighter:         #909eaf;

/* Other Colors */
$c-white:              #fff;
$c-lighter-blue:       #f1f7ff;
$theme-green:          #29bc8a;
$theme-yellow:         yellow;

$text-color:            $gray-darker;

$navbar-height: 80px;

$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-color: #fff;
$hamburger-layer-border-radius: 0;
$hamburger-types: (
  slider-r
);

// Progress-tracker

$progress-color: $green-color;
$progress-color-light: $green-color;
$progress-color-dark: $green-color;
$progress-color-grey: $grey-color;
$progress-color-grey-dark: #4A3730;
$marker-size: 12px;

// Pretty checkboxes
$pretty--color-success: $green-color;
