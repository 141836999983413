// _profile.scss

.manage-partners-header {
  background: url('../assets/images/Banner-member-profile-mobile.jpg') no-repeat;
  background-size: cover;
  min-height: 333px;
  @include media-breakpoint-up(md) {
    background: url('../assets/images/Banner-member-profile-desktop.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
    min-height: 300px;
  }
  h1 {
    font-size: 30px;
    @include media-breakpoint-up(md) {
      margin-left: auto;
      margin-right: auto;
      font-size: 45px;
    }
    text-transform: uppercase;
    color: #FFF;
    font-family: $font-bold;
  }
}
.manage-partners-body {
  // Overrides _home.scss
  .inset-content-background {
    padding: 40px 0 40px 0;
  }
  background-color: #E2E2E2;
  .manage-partners-content {
    .header {
      text-decoration: underline;
    }
    .partner {
      padding-top: 20px;
    }
    .partner + .partner {
      border-top: 1px dotted grey;
    }
    .stripe-connect-btn {
      width: 190px;
    }
    .profile-img {
      margin: 55px auto 30px auto;
      @include media-breakpoint-up(md) {
        margin: 33px auto 25px auto;
      }
      width: 235px;
      height: 235px;
    }
    .choose-organization {
      margin-top: 20px;
      margin-bottom: 30px;
      .btn-change-organization {
        color: $green-color;
        text-decoration: underline;
      }
    }
    .donations {
      border: 1px solid #4A3730;
      padding: 10px;
      margin-bottom: 20px;
      .donations-legend-wrapper {
        padding: 20px;
      }
      .legend {
        width: 20px;
        height: 15px;
        margin-left: 10px;
        margin-right: 10px;
        &.miles-legend {
          background-color: #4A3730;
        }
        &.donations-legend {
          background-color: $green-color;
        }
      }
      .charts-block {
        @include media-breakpoint-up(md) {
          border-right: 1px solid #4A3730;
        }
        margin-bottom: 40px;
      }
      .charts-row {
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }
    a.btn {
      margin-top: 20px;
      margin-bottom: 20px;
    
    }
  }
  .partner-option {
    margin-top: 15px;
  }
  .btn-change-organization {
    margin: 30px auto 30px auto;
  }
}
