.header-logos {
  @include flexbox();
  @include align-items(center);
  @include justify-content(flex-start);
  margin-bottom: 87px;
  @include breakpoint(767) {
    margin-bottom: 50px;
  }
  @include breakpoint(480) {
    margin-bottom: 35px;
  }
  .jsos-logo {
    width: 223px;
    max-width: 100%;
    height: auto;
    @include breakpoint(490) {
      width: 180px;
    }
    @include breakpoint(380) {
      width: 140px;
    }
  }
  .concur-logo {
    width: 171px;
    max-width: 100%;
    height: auto;
    margin-left: 43px;
    position: relative;
    top: 3px;
    @include breakpoint(490) {
      margin-left: 20px;
      width: 115px;
    }
    @include breakpoint(350) {
      width: 100px;
      top: 2px;
    }
  }
}
.concur-landing-page {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  @include flex-direction(column);
  min-height: 100vh;
  padding: 25px 0;
  &.success-page {
    @include align-items(flex-start);
    @include justify-content(flex-start);
    padding: 0;
  }
}
.concur-main-content {
  padding: 53px 58px;
  background-color: #fff;
  width: 800px;
  max-width: calc(100vw - 30px);
  @include breakpoint(991) {
    padding: 53px 30px;
  }
  @include breakpoint(767) {
    padding: 25px 0 55px;
  }
  @include breakpoint(480) {
    padding: 20px 0 35px;
  }
  .content-lading-page {
    .heading {
      font-family: $font-roboto-bold;
      line-height: 63px;
      font-size: $font-xl;
      margin-bottom: 36px;
      color: #464646;
      @include breakpoint(767) {
        line-height: 1.3;
        font-size: 40px;
        margin-bottom: 20px;
      }
      @include breakpoint(600) {
        font-size: 32px;
      }
      @include breakpoint(490) {
        font-size: $font-lg;
        margin-bottom: 20px;
      }
      @include breakpoint(350) {
        br {
          display: none;
        }
      }
    }
    .desc {
      font-family: $font-roboto-regular;
      font-size: $font-md;
      margin-bottom: 36px;
      line-height: 24px;
      color: #464646;
      @include breakpoint(767) {
        line-height: 1.4;
        font-size: $font-xs;
        margin-bottom: 20px;
      }
      @include breakpoint(600) {
        br {
          display: none;
        }
      }
      .link {
        color: rgb(0, 145, 255);
        text-decoration: none !important;
      }
    }
  }
  .wrap-btn {
    padding-bottom: 7px;
  }
  .btn-blue {
    color: #fff;
    font-family: $font-roboto-bold;
    font-size: $font-xs;
    padding: 7px 15px;
    background-color: #0091FF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    text-transform: uppercase;
    text-decoration: none;
    min-width: 145px;
    text-align: center;
    display: inline-block;
    border: none;
    outline: none;
  }
  .signup-form {
    margin-top: -17px;
    padding: 0 30px;
    @include breakpoint(768) {
      margin-top: 0;
      padding: 0;
    }
    .alert.alert-block {
      width: calc(100% - 30px);
      margin: 0 auto 15px;
      padding: 10px;
      font-family: $font-roboto-regular;
      font-size: $font-xs;
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }
    }
    .wrap-desc {
      margin-bottom: 38px;
      @include breakpoint(480) {
        margin-bottom: 25px;
      }
    }
    .desc {
      font-family: $font-roboto-regular;
      font-size: $font-sm;
      margin-bottom: $font-sm;
      color: #464646;
      line-height: 21px;
      @include breakpoint(767) {
        br {
          display: none;
        }
      }
    }
    .input-text {
      display: block;
      height: 36px;
      font-family: $font-roboto-regular;
      font-size: $font-xs;
      color: #464646;
      padding: 0 10px;
      box-shadow: inset 0 1px 3px rgba(0,0,0,.5);
      border: 1px solid #979797;
      border-radius: 3px;
      outline: none;
      width: 100%;
      &:focus {
        box-shadow: inset 0 0 2px rgba(74, 144, 226, 0.7);
        border: 1px solid rgb(74, 144, 226);
      }
      &.is-invalid {
        box-shadow: inset 0 0 2px rgba(208, 2, 27, 0.7);
        border: 1px solid rgb(208, 2, 27);
      }
    }
    .invalid-feedback {
      font-family: $font-roboto-bold;
    }
    .form-group {
      margin-bottom: 24px;
      @include breakpoint(480) {
        margin-bottom: 20px;
      }
    }
  }
  &.signup-content {
    padding-bottom: 55px;
    @include breakpoint(480) {
      padding-bottom: 10px;
    }
  }
  &.success-content {
    padding: 0;
    border: none;
    max-width: 100%;
    width: 100%;
  }
  .content-success-page {
    .wrap-banner {
      position: relative;
    }
    .banner {
      display: block;
      max-width: 100%;
      height: auto;
    }
    .heading {
      position: absolute;
      bottom: 40px;
      left: 59px;
      font-size: $font-xl;
      font-family: $font-roboto-bold;
      color: #fff;
      line-height: 63px;
      @include breakpoint(600) {
        line-height: 1.3;
        font-size: 32px;
        left: 25px;
        bottom: 25px;
      }
      @include breakpoint(480) {
        font-size: $font-lg;
        left: 15px;
        bottom: 10px;
      }
    }
    .desc {
      font-size: $font-sm;
      font-family: $font-roboto-regular;
      line-height: 21px;
      padding: 37px 59px 21px;
      color: #464646;
      margin-bottom: 0;
      @include breakpoint(600) {
        line-height: 1.3;
        font-size: $font-xs;
        padding: 25px;
      }
      @include breakpoint(480) {
        padding: 15px;
        br {
          display: none;
        }
      }
      .link {
        color: rgb(0, 145, 255);
        text-decoration: none !important;
      }
    }
    .wrap-btn {
      padding: 0 59px 47px;
      @include breakpoint(600) {
        padding: 0 25px 25px;
      }
      @include breakpoint(480) {
        padding: 0 15px 25px;
      }
      .btn-blue {
        min-width: 107px;
      }
    }
  }
}

.content-with-link {
  padding-top: 13px;
  font-family: $font-roboto-regular;
  font-size: $font-xs;
  color: #4A4A4A;
  margin-bottom: 0;
  .link {
    color: #0091FF;
    text-decoration: none;
  }
}

.concur-banner {
  position: relative;
  .home-banner-content {
    position: absolute;
    bottom: 12%;
    top: auto !important;
  }
  .home-banner-desc {
    font-family: $font-roboto-light;
    max-width: 620px;
    margin-bottom: 23px;
    @include breakpoint(1500) {
      max-width: 500px;
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 15px;
      max-width: 320px;
    }
  }
  .home-banner {
    width: 100%;
  }
  .btn-white {
    color: rgb(0, 145, 255);
    font-size: 24px;
    padding: 22px 42px;
    line-height: 29px;
    font-family: $font-bold;
    border-radius: 100px;
    display: inline-block;
    text-decoration: none !important;
    border: none;
    outline: none;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
    transition: all .2s;
    @include breakpoint(1199) {
      font-size: $font-sm;
      padding: 10px 25px;
    }
    @include breakpoint(767) {
      font-size: $font-sm;
      padding: 7px 20px;
    }
  }
  .home-banner-content {
    @include breakpoint(380) {
      padding-bottom: 30px;
      .home-banner-title {
        margin-bottom: 15px;
      }
    }
    @include breakpoint(350) {
      padding-bottom: 25px;
      .home-banner-title {
        margin-bottom: 15px;
      }
    }
  }
}

.jsos-features {
  padding: 100px 0;
  overflow: hidden;
  @include breakpoint(991) {
    padding: 60px 15px;
  }
  @include breakpoint(480) {
    padding: 15px 15px 30px;
  }
  .text-center {
    padding: 0 50px;
    @include breakpoint(1199) {
      padding: 0 15px;
    }
  }
  .row {
    margin: 0 -50px;
    @include breakpoint(1199) {
      margin: 0 -15px;
    }
  }
  .wrap-image {
    img {
      display: inline-block;
      width: 100%;
      margin-bottom: 28px;
      @include breakpoint(767) {
        margin-bottom: 15px;
        padding-top: 30px;
      }
    }
  }
  .feature-title {
    font-family: $font-roboto-bold;
    color: rgba(0, 0, 0, 0.85);
    font-size: 21px;
    margin-bottom: 30px;
    @include breakpoint(1199) {
      font-size: $font-md;
    }
    @include breakpoint(991) {
      font-size: $font-xs;
    }
    @include breakpoint(767) {
      margin-bottom: 15px;
    }
  }
  .feature-desc {
    font-family: $font-roboto-regular;
    color: rgba(0, 0, 0, 0.85);
    font-size: $font-md;
    margin-bottom: 0;
    line-height: 1.5;
    @include breakpoint(991) {
      font-size: $font-xs;
    }
  }
}

.concur-signup-form-container {
  padding: 120px 0 80px;
  background-color: rgb(220, 240, 255);
  @include breakpoint(991) {
    padding: 60px 0;
  }
  @include breakpoint(480) {
    padding: 30px 0;
  }
  .container {
    padding-left: 50px;
    padding-right: 50px;
    @include breakpoint(991) {
      padding: 0 15px;
    }
  }
  .form-title {
    font-size: 36px;
    line-height: 48px;
    color: #000;
    margin-bottom: 16px;
    font-family: $font-medium;
    @include breakpoint(1199) {
      font-size: 32px
    }
    @include breakpoint(991) {
      font-size: $font-lg;
    }
    @include breakpoint(767) {
      font-size: $font-md;
      line-height: 1.4;
    }
  }
  .form-desc {
    font-size: 21px;
    line-height: 1.4;
    color: #000;
    margin-bottom: 50px;
    font-family: $font-roboto-regular;
    @include breakpoint(991) {
      font-size: $font-md;
      margin-bottom: 30px;
    }
    @include breakpoint(767) {
      font-size: $font-xs;
      margin-bottom: 20px;
      line-height: 1.4;
    }
  }
  .form-group {
    margin-bottom: 40px;
    @include breakpoint(991) {
      margin-bottom: 20px;
    }
  }
  .form-control {
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    padding: 0 15px;
    height: 60px;
    border-radius: 3px;
    @include breakpoint(991) {
      height: 40px;
      font-size: $font-sm;
    }
    @include breakpoint(767) {
      font-size: $font-xs;
    }
  }
  .alert-block {
    width: calc(100% - 30px);
    margin: 0 auto 40px;
    font-size: 18px;
    padding: 10px;
    font-family: $font-roboto-regular;
    @include breakpoint(991) {
      margin: 0 auto 20px;
      font-size: $font-sm;
    }
    @include breakpoint(767) {
      font-size: $font-xs;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
  }
  .btn-blue {
    height: 60px;
    width: 244px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    background-color: rgb(0, 145, 255);
    color: #fff;
    font-size: 21px;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border: none;
    outline: none;
    @include breakpoint(991) {
      height: 40px;
      width: 150px;
      font-size: $font-sm;
    }
  }
  .list-concur-partners {
    list-style: none;
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
    @include flex-wrap(wrap);
    margin: 0 -15px 40px;
    padding: 0;
    @include breakpoint(767) {
      margin-bottom: 15px;
    }
    .partner {
      padding: 10px 15px;
    }
    .partner-logo {
      max-width: 100%;
      width: 350px;
      height: auto;
      @include breakpoint(767) {
        width: 230px;
      }
    }
  }
  .btn-green {
    outline: none;
    font-size: 28px;
    line-height: 36px;
    padding: 15px 40px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    @include breakpoint(991) {
      font-size: $font-md;
      line-height: 20px;
      padding: 10px 25px;
    }
  }
}

.concur-dowload-information {
  padding: 100px 0 40px;
  @include breakpoint(991) {
    padding: 50px 0 20px;
  }
  @include breakpoint(767) {
    padding: 30px 0 0;
  }
  .title {
    font-size: 24px;
    line-height: 32px;
    font-family: $font-medium;
    margin-bottom: 25px;
    color: #000;
    @include breakpoint(991) {
      font-size: $font-md;
      line-height: 1.4;
    }
    @include breakpoint(767) {
      margin-bottom: 15px;
    }
  }
  .desc {
    font-family: $font-roboto-regular;
    font-size: 21px;
    line-height: 28px;
    padding: 0;
    margin: 0;
    @include breakpoint(991) {
      font-size: $font-sm;
      line-height: 1.4;
    }
    @include breakpoint(767) {
      font-size: $font-xs;
    }
    @include breakpoint(550) {
      br {
        display: none;
      }
    }
    .link {
      color: rgb(0, 145, 255);
      text-decoration: none !important;
    }
  }
}