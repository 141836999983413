// _legal.scss

#legal {
  .legal-header {
    background: url('../assets/images/About-banner-mobile.jpg') no-repeat;
    background-size: cover;
    min-height: 333px;
    @include media-breakpoint-up(md) {
      background: url('../assets/images/About-banner-desktop.jpg') no-repeat;
      background-size: cover;
      min-height: 445px;
    }
    h1 {
      margin-left: auto;
      margin-right: auto;
      font-size: 30px;
      @include media-breakpoint-up(md) {
        margin-left: auto;
        margin-right: auto;
        font-size: 45px;
      }
      text-transform: uppercase;
      color: #FFF;
      font-family: $font-bold;
    }
  }
  section {
    padding-top: 30px;
    padding-bottom: 30px;
    h2 {
      font-size: 36px;
      text-transform: uppercase;
    }
    h3 {
      font-size: 30px;
    }
    p {
      font-size: $font-sm;
      line-height: 32px;
    }
  }
}
