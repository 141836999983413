// _contact.scss

#userprofile-update {
  .terms-and-conditions a {
    color: $green-color;
  }
  .userprofile-update-header {
    background: url('../assets/images/Banner-userprofile-update-mobile.jpg') no-repeat;
    background-size: cover;
    min-height: 333px;
    @include media-breakpoint-up(md) {
      background: url('../assets/images/Banner-userprofile-update-desktop.jpg') no-repeat;
      background-size: cover;
      min-height: 445px;
    }
    h1 {
      margin-left: auto;
      margin-right: auto;
      font-size: 30px;
      @include media-breakpoint-up(md) {
        margin-left: auto;
        margin-right: auto;
        font-size: 45px;
      }
      text-transform: uppercase;
      color: #FFF;
      font-family: $font-bold;
    }
  }
  section {
    padding-top: 30px;
    padding-bottom: 30px;
    @include media-breakpoint-up(md) {
      padding: 60px 200px 30px 200px;
    }
    h2 {
      @include media-breakpoint-up(md) {
        font-size: 30px;
      }
      font-size: $font-md;
      text-transform: uppercase;
    }
    h3 {
      font-size: $font-md;
    }
    h2, h3 {
      margin-bottom: 20px;
    }
    p {
      font-size: $font-sm;
      line-height: 32px;
    }
    form {
      margin-top: 40px;
    }
    label {
      text-transform: uppercase;
    }
    .btn {
      box-shadow: 3px 3px 10px 0 rgba(0,0,0,0.11);
    }
  }
  div.user-settings {
    div#div_id_donation_settings > label {
      font-family: $font-bold;
      @include media-breakpoint-up(md) {
        font-size: 30px;
      }
      font-size: $font-md;
    }
    div#div_id_maximum_annual_dollar_charge {
      //@include make-col(6)
      label {
        float: right;
      }
      input#id_maximum_annual_dollar_charge {
        height: calc(2.25rem + 2px);
      }
      .input-group {
        @include make-col(6)
      
      }
    }
    .address {
      @include media-breakpoint-up(md) {
        margin-top: 10px;
      }
    }
    .address img, .email img {
      float: left;
      margin-right: 10px;
    }
    .address, .email, .social {
      margin-bottom: 50px;
    }
    hr {
      margin-bottom: 50px;
    }
    .social {
      a {
        margin-right: 20px;
      }
    }
    .btn-green, .btn-light {
      width: 200px;
      height: 50px;
    }
  }
  div.payment-info {
    p.change-credit-card {
      margin-top: 40px;
    }
    #submit-credit-card-change {
      margin-top: 40px;
    }
  }
  div.cancel-account {
    h2 {
      margin-top: 30px;
    }
  }
  .email_forwarding_help_text {
    font-size: 18px;
    font-weight: bold;
  }
}
