// _signup.scss

// Temorary CSS for progress bar.
// TODO: Remove this.
.progress-bar-warning {
    background-color: yellow;
}

.progress-bar-danger {
    background-color: red;
}

.progress-bar-success {
    background-color: green;
}

// Temporarily remove password warning text.
// TODO: Remove this.
.password_strength_info {
  display: none;
}

.progress-bloc {
  text-align: left;
  &:before {
    content: "Password strength: ";
    font-size: $font-sm;
    font-family: $font-regular;
  }
}

.sign-up-banner {
  background: url('../assets/images/Banner-sign-up-mobile.jpg') no-repeat fixed;
  background-position: center top;
  background-size: 100% auto;
  height: 240px;
  width: 100%;
  @include media-breakpoint-up(md) {
    position: fixed;
    background: url('../assets/images/Banner-sign-up-desktop.jpg') no-repeat fixed;
    background-size: auto 100%;
    background-position: left top;
    height: 100vh;
  }
}
.sign-up-section {
  .sign-up-wrapper {
    h5:not(.card-header) {
      text-transform: uppercase;
      font-size: $font-md;
    }
    .progress-tracker h5 {
      font-size: $font-sm;
    }
    p {
      font-size: $font-sm;

    }
    .progress-tracker {
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }
    .btn-wrapper {
      width: 100%;
    }
    .form-check {
      margin-top: 30px;
      margin-bottom: 20px;
      .form-check-label {
        font-size: $font-sm;
      }
    }
  }
  padding-top: 30px;
  @include media-breakpoint-up(md) {
    padding-top: 50px;
  }
  .btn-green {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .email-pass-cta {
    color: $green-color;
    margin-bottom: 20px;
  }
  p > a {
    color: $green-color;
  }
  padding-bottom: 50px;
  @include media-breakpoint-up(md) {
    //height: 100vh;
  }
}
